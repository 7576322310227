<template>
  <b-modal
    id="edit-status-modal"
    ref="edit-status-modal"
    title="Add/Edit Status"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="doLoadData"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="newStatusForm">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="status-name"
                v-model="newStatusName"
                label="Status Name"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="2">
            <validation-provider
              #default="{ errors }"
              name="percent"
              rules="required|numeric|min_value:0|max_value:100"
            >
              <HeroInputNumber
                id="status-percent"
                v-model="newStatusPercent"
                label="Percent"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="4">
            <HeroButtonAction type="button" variant="primary" class="mt-2" @click="addStatus(status)">
              Add
            </HeroButtonAction>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <HeroCheckbox
              id="outside-funnel"
              v-model="newStatusOutsideFunnelFlag"
              label="Outside Funnel"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <hr>

    <!-- Inside Funnel Status -->
    <validation-observer ref="insideFunnelStatusForm">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12">
            <span class="label" style="font-size: 1rem; font-weight: 600;">
              Inside Funnel
            </span>
          </b-col>
        </b-row>
        <b-row v-for="insideStatus in insideFunnelStatus" :key="insideStatus.id">
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="status-name"
                :key="`${insideStatus.id}-name`"
                v-model="insideStatus.name"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <validation-provider
              #default="{ errors }"
              name="percent"
              rules="required|numeric|min_value:0|max_value:100"
            >
              <HeroInputText
                id="status-percent"
                :key="`${insideStatus.id}-percent`"
                v-model="insideStatus.percent"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="2">
            <HeroButtonAction :key="`${insideStatus.id}-save-button`" type="button" variant="primary" @click="updateStatus(insideStatus)">
              Save
            </HeroButtonAction>
          </b-col>

          <b-col cols="2">
            <HeroButtonAction :key="`${insideStatus.id}-delete-button`" type="button" variant="danger" @click="deleteStatus(insideStatus)">
              Delete
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <hr>

    <!-- Outside Funnel -->
    <validation-observer ref="outsideFunnelStatusForm">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12">
            <span class="label" style="font-size: 1rem; font-weight: 600;">
              Outside Funnel
            </span>
          </b-col>
        </b-row>
        <b-row v-for="outsideStatus in outsideFunnelStatus" :key="outsideStatus.id">
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="status-name"
                :key="`${outsideStatus.id}-name`"
                v-model="outsideStatus.name"
                :required="true"
                :clearable="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2" />

          <b-col cols="2">
            <HeroButtonAction :key="`${outsideStatus.id}-save-button`" type="button" variant="primary" @click="updateStatus(outsideStatus)">
              Save
            </HeroButtonAction>
          </b-col>

          <b-col cols="2">
            <HeroButtonAction :key="`${outsideStatus.id}-delete-button`" type="button" variant="danger" @click="deleteStatus(outsideStatus)">
              Delete
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  numeric,
} from '@validations'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroInputNumber,
    HeroCheckbox,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      numeric,

      newStatusName: '',
      newStatusPercent: 0,
      newStatusOutsideFunnelFlag: false,

      insideFunnelStatus: [],
      outsideFunnelStatus: [],

      levelOptions: [
        { text: 'Heroleads', value: 'Heroleads' },
        { text: 'Account', value: 'Account' },
        { text: 'Campaign', value: 'Campaign' },
      ],
      level: 'Campaign',
      reason: '',
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['edit-status-modal'].hide()
      this.$emit('success')
    },

    async doLoadData() {
      this.showOverlay = true

      this.insideFunnelStatus = []
      this.outsideFunnelStatus = []

      const status = await this.getStatus()

      console.dir(status)

      if (status.length > 0) {
        status.forEach(eachStatus => {
          if (eachStatus.outside_funnel_flag === 0) {
            this.insideFunnelStatus.push({ ...eachStatus })
          }
        })

        status.forEach(eachStatus => {
          if (eachStatus.outside_funnel_flag === 1) {
            this.outsideFunnelStatus.push({ ...eachStatus })
          }
        })
      }

      this.showOverlay = false
    },

    async getStatus() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const url = `/herovision/lead-management/status/${this.campaignId}`
        const response = await axios.get(url, axiosConfig)

        return response.data.data
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        return []
      }
    },

    async addStatus() {
      const validationResult = await this.$refs.newStatusForm.validate()

      if (validationResult) {
        this.showOverlay = true

        const body = {
          campaign_id: this.campaignId,
          name: this.newStatusName,
          percent: this.newStatusPercent,
          outside_funnel_flag: this.newStatusOutsideFunnelFlag ? 1 : 0,
        }

        try {
          const token = store.getters['heroAiAuthentications/sanctumToken']
          const axiosConfig = AxiosConfig.getJsonWithToken(token)

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axios.post('/herovision/lead-management/status', body, axiosConfig)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          this.doLoadData()
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    async updateStatus(status) {
      let validationResult

      if (status.outside_funnel_flag === 0) {
        validationResult = await this.$refs.insideFunnelStatusForm.validate()
      } else {
        validationResult = await this.$refs.outFunnelStatusForm.validate()
      }

      if (validationResult) {
        this.showOverlay = true

        const body = {
          campaign_id: this.campaignId,
          name: status.name,
          percent: status.percent,
          outside_funnel_flag: status.outside_funnel_flag,
        }

        try {
          const token = store.getters['heroAiAuthentications/sanctumToken']
          const axiosConfig = AxiosConfig.getJsonWithToken(token)

          // ส่งข้อมูลไปบันทึกที่ Lead Management
          const response = await axios.put(`/herovision/lead-management/status/${status.id}`, body, axiosConfig)

          await this.$swal({ ...SweetAlert.success, text: response.data.message })
          this.doLoadData()
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        }

        this.showOverlay = false
      }
    },

    async deleteStatus(status) {
      this.showOverlay = true

      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        // ส่งข้อมูลไปบันทึกที่ Lead Management
        const response = await axios.delete(`/herovision/lead-management/status/${status.id}`, axiosConfig)

        await this.$swal({ ...SweetAlert.success, text: response.data.message })
        this.doLoadData()
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      this.showOverlay = false
    },
  },
}
</script>
