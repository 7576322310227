<template>
  <b-modal
    id="download-leads-modal"
    ref="download-leads-modal"
    title="Download Leads"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <b-form @submit.stop.prevent>
      <b-row>
        <b-col cols="12">
          <HeroVueSelect
            id="convert-type"
            v-model="convertType"
            label="Convert Phone Number"
            column="6"
            :multiple="false"
            :clearable="false"
            :options="convertTypeOptions"
          />
        </b-col>

        <b-col cols="12">
          <HeroCheckbox
            id="phone-as-string"
            v-model="phoneAsString"
            label="Phone As String"
            column="6"
          />
        </b-col>
      </b-row>
    </b-form>

    <b-row>
      <b-col cols="12">
        <HeroButtonAction type="button" variant="primary" @click="doExportLeads">
          Download
        </HeroButtonAction>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    HeroVueSelect,
    HeroCheckbox,
    HeroButtonAction,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      convertType: '1',
      convertTypeOptions: [
        { text: 'No convert', value: '1' },
        { text: 'Convert +66 to 0', value: '2' },
        { text: 'Convert 0 to +66', value: '3' },
      ],
      phoneAsString: true,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['download-leads-modal'].hide()
    },

    async doExportLeads() {
      this.showOverlay = true

      let query = 'export_type=excel&'

      // TODO: แก้ไขให้ Filter เป็นไปตามหน้าจอ
      query += `start_date=${this.filter.fromDate} 00:00:00&`
      query += `end_date=${this.filter.toDate} 23:59:59&`
      query += 'types[0]=submitted&'
      query += 'types[1]=answered&'
      query += 'types[2]=missed-call&'
      query += 'types[3]=chatbot&'
      query += `convert_type=${this.convertType}&`
      query += `phone_as_string=${this.phoneAsString}`

      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const url = `/herovision/lead-management/export-leads/${this.filter.campaignId}?${query}`
        const response = await axios.get(url, axiosConfig)

        window.open(response.data.filePath)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      } finally {
        this.showOverlay = false
      }
    },
  },
}
</script>
